import { Link } from "gatsby"
import React, { useMemo, useState } from "react"
import queryString from 'query-string';
import "@babel/polyfill";

import algoliasearch from "algoliasearch/lite"
import { InstantSearch, connectStateResults, Highlight, Hits, Index, Pagination, SearchBox, Configure } from "react-instantsearch-dom"

import Layout from "../layouts"
import Head from "../components/head"
import SectionHero from "../components/section-hero";

import '../css/site-search.css';

const isBrowser = typeof window !== "undefined"

const SiteSearch = (props) => {

  const indices = [
    { name: `wp_page`, title: `Pages` },
    { name: `wp_post`, title: `Posts` },
    { name: `wp_solution`, title: `Solutions` },
    { name: `wp_faq`, title: `FAQs` },
    { name: `wp_country`, title: `Countries` },
    { name: `wp_event`, title: `Events` },
    { name: `wp_who_we_serve`, title: `Who We Serve` },
  ];
  let params = queryString.parse(props.location.search)
  params.q = params.q ? params.q : '';

  const [query, setQuery] = useState(params.q)
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  );

  if(!isBrowser)
    return(<p></p>);

  return(
    <Layout>
      <Head
        title={"Search: "+query}
      />
      <SectionHero section="landing" title={"Search: "+query}/>

      <div className="container site-search-container">
        <div className="col-sm-12">
          <div className="smallGrid">
            <InstantSearch
              searchClient={searchClient}
              indexName={indices[0].name}
              refresh={true}
            >
              <div className="search-box-wrapper">
                <p>Search our site</p>
                <SearchBox
                  className="searchbox"
                  defaultRefinement={query}
                  searchAsYouType={false}
                  onSubmit={(e) => {e.preventDefault(); setQuery(e.target.elements[0].value)}}
                  translations={{
                    placeholder: 'Search',
                  }}
                />
              </div>
              <div className="search-results-sections">
                {indices.map(index => {
                  return(
                    <div className="search-results-section col-md-4" key={index.name}>
                      <HitsInIndex index={index} key={index.name} />
                    </div>
                  )
                })}
              </div>
            </InstantSearch>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default SiteSearch

const DisplayContent = connectStateResults(({ searchResults, index }) => {
  const hitCount = searchResults && searchResults.nbHits;
  const totalPages = searchResults && searchResults.nbPages

  return hitCount > 0 ? (
    <div className="search-results">
      <Hits className="Hits" hitComponent={PageHit} />
      { totalPages > 1 &&
        <div className="pagination">
          <Pagination />
        </div>
      }
    </div>
  ) : (
    <div className="search-results">
      <h3>No Results Found</h3>
    </div>
  )
})

const PageHit = ({ hit }) => (
  <div>
    <Link to={hit.url}>
      <h4>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </h4>
    </Link>
  </div>
)

const HitsInIndex = ({ index }) => {
  return (
    <Index indexName={index.name}>
      <Configure hitsPerPage={6} />
      <h2>{index.title}</h2>
      <DisplayContent index={index} />
    </Index>
  )
}